<template>
  <div class="modal modal_wrap">
    <div class="modal_popup modal_ligthBg">
      <div class="modal_content">
        <h2 class="titlePage titlePage_textLeft">Order Settings</h2>

        <Form action="" class="form" v-on:submit.prevent>

          <h3 class="text">Installation</h3>


          <div class="row align-items-center">
            <div class="col-6">
              <span class="form__labelTitle">Time required</span>
            </div>
            <div class="col-6">
              <label class="form__label form__label_row">
                <Field type="text"
                       class="form__input form__input_m100"
                       name="time"
                       :rules="'between:0, 999'"
                       v-model="settings.installation_time"
                       @input="debouncedChangeTimeRequired"
                />
                <span class="form__labelTitle">hr</span>
              </label>
              <ErrorMessage class="form__error"  name="time" />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="form__label form__label_row">
                <input type="checkbox"
                       hidden="hidden"
                       name="installation"
                       value="installation"
                       v-model="settings.free_installation"
                       class="form__radio"
                       @input="debouncedChangeTimeRequired"
                />
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">Free installation</span>
              </label>
            </div>
          </div>


          <div class="row align-items-center">
            <div class="col-6">
              <label class="form__label form__label_row">
                <input type="checkbox"
                       hidden="hidden"
                       name="fee"
                       value="width"
                       class="form__radio"
                       v-model="settings.fixed_fee"
                />
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">Fixed Fee</span>
              </label>
            </div>
            <div class="col-6">
              <label class="form__label form__label_row">
                <Field type="text"
                       class="form__input form__input_m100"
                       name="fixed fee"
                       :rules="'required|between:0, 9999'"
                       :disabled="!settings.fixed_fee"
                       v-model="settings.installation_fee"
                       @input="debouncedChangeTimeRequired"
                />
                <span class="form__labelTitle">AUD</span>
              </label>
              <ErrorMessage class="form__error"  name="fixed fee" />
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-6">
              <label class="form__label form__label_row">
                <input type="checkbox"
                       hidden="hidden"
                       name="rate"
                       value="width"
                       class="form__radio"
                       v-model="settings.min_rate"
                       :disabled="disableMinRate"
                />
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">Min rate</span>
              </label>
            </div>
            <div class="col-6">
              <label class="form__label form__label_row">
                <Field type="text"
                       class="form__input form__input_m100"
                       name="min rate"
                       :rules="'required|between:0, 9999'"
                       :disabled="!settings.min_rate"
                       v-model="settings.min_rate_value"
                       @input="debouncedChangeTimeRequired"
                />
                <span class="form__labelTitle">$/hr</span>
              </label>
              <ErrorMessage class="form__error"  name="min rate" />
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-6">
              <p class="text text_default">Installation Fee:</p>
            </div>
            <div class="col-6">
              <p class="text text_default">{{settings.installation_fee}}$</p>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-6">
              <p class="text text_default">Rate per hour:</p>
            </div>
            <div class="col-6">
              <p class="text text_default">{{settings.rate_per_hour}}$</p>
            </div>
          </div>

          <div class="divider"></div>

          <h3 class="text">Discount</h3>

          <div class="row align-items-center">
            <div class="col-6">
              <label class="form__label form__label_row">
                <input type="radio"
                       hidden="hidden"
                       name="ask"
                       value="per"
                       class="form__radio"
                       v-model="settings.discount_type"
                />
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">Percentage</span>
              </label>
            </div>
            <div class="col-6">
              <label class="form__label form__label_row">
                <Field type="text"
                       class="form__input form__input_m100"
                       name="discount"
                       :rules="'between:0.0,99.9'"
                       v-model="settings.discount_pct"
                       @input="debouncedChangeTimeRequired"
                       :disabled="settings.discount_type === 'aud'"
                />
                <span class="form__labelTitle">%</span>
              </label>
              <ErrorMessage class="form__error"  name="discount" />
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-6">
              <label class="form__label form__label_row">
                <input type="radio"
                       hidden="hidden"
                       name="ask"
                       value="aud"
                       class="form__radio"
                       v-model="settings.discount_type"
                       @change="debouncedChangeTimeRequired"
                />
                <span class="form__radio_icon"></span>
                <span class="form__labelTitle">Сurrency</span>
              </label>
            </div>
            <div class="col-6">
              <label class="form__label form__label_row">
                <Field type="text"
                       class="form__input form__input_m100"
                       name="discount aud"
                       :rules="'between:-999999.0, 999999.0'"
                       v-model="settings.discount_amt"
                       :disabled="settings.discount_type === 'per'"
                       @input="debouncedChangeTimeRequired"
                />
                <span class="form__labelTitle">AUD</span>
              </label>
              <ErrorMessage class="form__error"  name="discount aud" />
            </div>
          </div>

        </Form>
        <div class="modal__footer modal__footer_borderTop">
          <p class="text">Order Total: <span>{{settings.order_total}}</span></p>
        </div>
      </div>

      <button type="button" class="btn btn_transparent" @click="close">
         save
      </button>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';

  export default {
    name: "SettingsWindow",

    data() {
      return {}
    },

    methods: {
      close() {
        this.$store.commit('orderPreview/setSettingsWindow', false);
      },
      debouncedChangeTimeRequired: _.debounce(function (e) {
       // this.$validator.validate().then(valid => {
          if (/*valid &&*/ e.target.value !== '') {

            let input = e.target.name;

            if (input === 'time') {
              this.$store.dispatch('settingsOrder/putTimeRequired');
            }

            if (input === 'installation') {
              this.$store.dispatch('settingsOrder/putFreInstallation');
            }

            if (input === 'fixed fee') {
              this.$store.dispatch('settingsOrder/putFixedFee');
            }

            if (input === 'min rate') {
              this.$store.dispatch('settingsOrder/putMinRate');
            }

            if (input === 'discount') {
              this.$store.dispatch('settingsOrder/changeDiscount');
            }

            if (input === 'discount aud') {
              this.$store.dispatch('settingsOrder/changeDiscount');
            }

            if (input === 'ask') {
                this.$store.dispatch('settingsOrder/changeDiscount');
            }

          }
       // });
      }, 1000)
    },

    computed: {
      ...mapState('settingsOrder', [
          'settings'
        ]),

      disableMinRate(){
        if(parseFloat(this.settings.installation_time) === 0){
          return true;
        }
        else return false;
      }
    },

    watch: {
      'settings.free_installation'() {
        if (this.settings.free_installation) {
          this.settings.fixed_fee = false;
          this.settings.min_rate = false;
        }
      },
      'settings.fixed_fee'() {
        if (this.settings.fixed_fee) {
          this.settings.free_installation = false;
          this.settings.min_rate = false;
        }
      },
      'settings.min_rate'() {
        if (this.settings.min_rate && parseFloat(this.settings.installation_time) !== 0) {
          this.settings.free_installation = false;
          this.settings.fixed_fee = false;
        }
      },

      'settings.discount_type'() {
        if (this.settings.discount_type === true || this.settings.discount_type === 'aud') {
          this.settings.discount_type = 'aud';
        }
        else  this.settings.discount_type = 'per'
      },
    },

    created() {
      this.$store.dispatch('settingsOrder/getSettings');
    }
    ,
  }
</script>

<style scoped lang="scss">
  @import "../../../src/assets/scss/utils/vars";

  .form {
    .row {
      margin-bottom: 15px;
      .text_default {
        margin: 0;
      }
    }
    &__input {
      margin-right: 5px;
      &:disabled {
        opacity: .6;
      }
    }
    &__label {
      &_row {
        margin-right: 0;
      }
    }
  }

  .modal {
    &_popup {
      padding: 2.5rem 2rem;
    }

    &_content {
      padding: 0 10px;
    }

    &__close {
      top: 10px;
    }

    .titlePage {
      color: $navy;
    }
  }

  .btn.btn_transparent{
    margin-bottom: 0;
  }


</style>
